import axios from 'axios'

export const api = axios.create({

  baseURL: 'https://back-onchainbubbles.technorely.com/api/v1/',

  headers: {
    Accept: '*/*',
  },
})
