import { FC } from 'react'

import { useQuery } from '@tanstack/react-query'

import { api } from '@/api'
import { Aside } from '@/components/aside'
import { BubblesPage as Bubbles } from '@/components/bubbles'
import { Header } from '@/components/header'
import { useStore } from '@/store'
import { TokenWithMetadata } from '@/types/tokenWithMetadata.type'

import styles from './styles.module.scss'

const App: FC = () => {
  const { chosenNetwork } = useStore()

  const { data: coins, isSuccess } = useQuery({
    queryKey: ['bubbles', chosenNetwork.id],
    queryFn: () =>
      api
        .get(`tokens/top-tokens-list?networkId=${chosenNetwork.id}&resolution=60`)
        .then((res) => res.data as TokenWithMetadata[]),
  })

  return (
    <main className={styles.main}>
      <div className={styles.container}>
        <Aside />

        <div className={styles.content}>
          <Header />

          {isSuccess && <Bubbles coins={coins} />}
        </div>
      </div>
    </main>
  )
}

export { App }
