import { FC } from 'react'

import { TokenWithMetadata } from '@/types/tokenWithMetadata.type'

import { Bubbles } from './bubbles'

type Props = {
  coins: TokenWithMetadata[]
}

const BubblesPage: FC<Props> = ({ coins }) => {
  return <Bubbles coins={coins} />
}

export { BubblesPage }
