import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { api } from '@/api'
import { NetworksList } from '@/components/networks-list'
import { useStore } from '@/store'
import { Network } from '@/types/network.type'
import { SearchInput } from '@/ui-components/search-input'

import styles from './styles.module.scss'

const Aside = () => {
  const { searchNetwork, setSearchNetwork } = useStore()

  const { data: networks } = useQuery({
    queryKey: ['networks'],
    queryFn: () => api.get('tokens/networks').then((res) => res.data as Network[]),
  })

  const filteredNetworks = useMemo(
    () =>
      networks?.filter(({ name }) =>
        name.toLowerCase().includes(searchNetwork.toLowerCase().trim()),
      ),
    [searchNetwork, networks],
  )

  return (
    <main className={styles.main}>
      <aside className={styles.aside}>
        <div className={styles['top-part']}>
          <h1 className={styles.title}>ONCHAINBUBBLES</h1>

          <SearchInput
            placeholder="Search network"
            searchInput={searchNetwork}
            setSearchInput={setSearchNetwork}
          />
        </div>

        <div className={styles['bottom-part']}>
          {filteredNetworks && <NetworksList networks={filteredNetworks} />}
        </div>
      </aside>

      <div className={styles.content}>
        <header className={styles.header}></header>
      </div>
    </main>
  )
}

export { Aside }
